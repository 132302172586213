import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import axios from "axios";

import { ButtonLeft, ButtonRight } from "./buttons";

function Item({ pubDate, title, imageUrl, text }) {
  return (
    <div className="py-4 px-3 md:w-1/3">
      <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
        <div className="flex flex-wrap items-center m-2">
          <h2 className="bg-gray-200 uppercase font-medium text-gray-700 text-xs font-semibold m-1 p-1">
            {pubDate}
          </h2>
          <h1 className="uppercase font-semibold text-gray-700 m-1">{title}</h1>
        </div>
        <div className="aspect-w-3 aspect-h-2">
          <img className="object-cover" src={imageUrl} alt="image" />
        </div>
        <p className="p-4">{text}</p>
      </div>
    </div>
  );
}

function News() {
  const [newsItems, setNewsItems] = useState([]);
  const [page, setPage] = useState({
    offset: 0,
    limit: 3
  });

  useEffect(() => {
    setNewsItems(window.whlotos.news);
  }, []);

  useEffect(() => {
    axios.get("/api/news/", { params: { ...page } }).then(response => {
      setNewsItems(response.data);
    });
  }, [page]);

  const clickButtonLeft = () => {
    if (page.offset !== 0) {
      setPage({ ...page, offset: page.offset - 1 });
    }
  };

  const clickButtonRight = () => {
    setPage({ ...page, offset: page.offset + 1 });
  };

  return (
    <div className="tw-container-empty mx-auto my-4">
      <div className="m-2 flex flex-wrap items-center justify-between">
        <h1 className="mb-2 uppercase text-gray-700 text-xl title-font font-semibold">
          Новости
        </h1>
        <div>
          <ButtonLeft onClick={clickButtonLeft} />
          <ButtonRight onClick={clickButtonRight} />
        </div>
      </div>
      <div className="flex flex-wrap">
        {newsItems.slice(0, 3).map(post => {
          return (
            <Item
              key={post.title}
              pubDate={post.pubDate}
              title={post.title}
              imageUrl={post.imageUrl}
              text={post.text}
            />
          );
        })}
      </div>
    </div>
  );
}

ReactDOM.render(<News />, document.getElementById("react-news"));
