import React from "react";

function ButtonLeft({ onClick }) {
  return (
    <a
      onClick={onClick}
      className="cursor-pointer p-1 h-8 w-8 border border-gray-200 border-opacity-60 rounded-sm hover:border-gray-300 active:border-gray-700 text-gray-700 inline-flex items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </a>
  );
}

function ButtonRight({ onClick }) {
  return (
    <a
      onClick={onClick}
      className="cursor-pointer p-1 h-8 w-8 border border-gray-200 border-opacity-60 rounded-sm active:border-gray-700 hover:border-gray-300 text-gray-700 inline-flex items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </a>
  );
}

export { ButtonLeft, ButtonRight };
